export const socialNetworks = [
  {
    name: "facebook",
    link: "https://www.facebook.com/profile.php?id=61554881159420",
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/chance_appp?igsh=cDUycTJ6djltaTQ3",
  },
  {
    name: "tiktok",
    link: "https://www.tiktok.com/@chance_app?_t=8ivF0N64uY9&_r=1",
  },
]
