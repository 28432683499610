import closePeople from "../../img/png/close-people.png";
import information from "../../img/png/information.png";
import blindPeople from "../../img/png/blind-people.png";
import lookingForWork from "../../img/png/looking-for-work.png";
import peopleDisable from "../../img/png/people-disable.png";
import communicationProblems from "../../img/png/communication-problems.png";
import hearingImpairment from "../../img/png/hearing-impairment.png";
import medicalCare from "../../img/png/medical-care.png";

import medicalServices from "../../img/png/medical-services.png";
import dailyTasks from "../../img/png/daily-tasks.png";
import interactingAndChatting from "../../img/png/interacting-and-chatting.png";

import Andrii from "../../img/png/Andrii-Sukhmanovskyi.png";
import Ivan from "../../img/png/Ivan-Levytskyi.png";
import Sofia from "../../img/png/Sofia-Mandziuk.png";
import Natalia from "../../img/png/Natalia-Fedotova.png";

export const uk = {
  "navigation": [
    {
      "title": "для кого",
      "link": "/#about-people",
    },
    {
      "title": "можливості",
      "link": "/#opportunity",
    },
    {
      "title": "про нас",
      "link": "/#about-us",
    },
    {
      "title": "питання",
      "link": "/#faq",
    },
    {
      "title": "контакти",
      "link": "/#contacts",
    }
  ],

  "For everyone in need": "для всіх, хто потребує",
  "supported by": "за підтримки",
  "creating changes": "створюємо зміни",
  "in people’s lives": "у житті людей",
  "download the app": "Завантажуй застосунок",

  "It’s": "це",
  "about humans": "про людей",
  "and their lives": "та їхнє життя",
  "chance is for everyone": `Chance app призначений для людей з інвалідністю, тому ми створюємо застосунок, щоб зробити життя простішим та доступнішим для кожного!`,
  "our inspiration is people": "наше натхнення — це люди з інвалідністю, і ми прагнемо забезпечити кожного силою та вірою у себе й свої можливості",

  "forWhom": [
    {
      id: 1,
      name: "for close people",
      photo: closePeople,
      description: "для родичів, близьких людей або опікунів",
      position: {
        top: 248,
        left: 0,
      },
    },
    {
      id: 2,
      name: "for information",
      photo: information,
      description: "для тих, хто хоче ознайомитись з темою доступності",
      position: {
        top: 137,
        right: 0,
      },
    },
    {
      id: 3,
      name: "for blind users",
      photo: blindPeople,
      description: "для незрячих користувачів",
      position: {
        top: 534,
        left: 316,
      },
    },
    {
      id: 4,
      name: "for people looking for work",
      photo: lookingForWork,
      description: "для людей з інвалідністю, які шукають роботу",
      position: {
        top: 534,
        right: 210,
      },
    },
    {
      id: 5,
      name: "for people disable move",
      photo: peopleDisable,
      description: "для людей з обмеженими можливостями фізичного руху",
      position: {
        top: 771,
        left: 0,
      },
    },
    {
      id: 6,
      name: "for people with communication problems",
      photo: communicationProblems,
      description: "для людей з генетичними порушеннями, мовлення та комунікації",
      position: {
        top: 822,
        right: 422,
      },
    },
    {
      id: 7,
      name: "for people with hearing impairment",
      photo: hearingImpairment,
      description: "для людей із вадами слуху",
      position: {
        top: 1041,
        left: 316,
      },
    },
    {
      id: 8,
      name: "for people in need of medical care",
      photo: medicalCare,
      description: "для людей з інвалідністю, які потребують медичної допомоги",
      position: {
        top: 964,
        right: 0,
      },
    },
  ],

  "open the features for": "відкриваємо функції",
  "access and comfort": "доступу і зручності",
  "new horizons for everyone": "нові горизонти для кожного",

  "functions": [
    {
      id: 1,
      name: "медичні послуги",
      description: "у нашому застосунку облік вашого здоров'я — це не лише функція, але і наша турбота, тому ви можете користуватись зручними можливостями, що включають:",
      photo: medicalServices,
      cheacks: ["запис до лікаря", "нагадування про прийом ліків", "кнопка SOS"],
    },
    {
      id: 2,
      name: "повсякденні завдання",
      description: "полегшуємо звичайне життя за допомогою якісних функцій, щоб ви могли робити справи швидше та вправніше:",
      photo: dailyTasks,
      cheacks: ["пошук роботи", "запис інформації та нотаток", "чат спілкування"],
    },
    {
      id: 3,
      name: "взаємодія та пілкування",
      description: "важливо знайти підхід до кожного, яка допомогає у багатьох справах",
      photo: interactingAndChatting,
      cheacks: ["навігація та карти", "відстеження незрячих"],
    },
  ],

  "this is Andriy": "це Андрій, наш керівник, який палає ідеєю створення цього проєкту",
  "have a chat": "поспілкуватись",
  "socialisation": "соціалізація та почуття важливості — найголовніші речі, що дає наш застосунок",
  "we are creating": "ми створюємо універсальний і доступний застосунок для людей з інвалідністю, що вирішує конкретні потреби та враховує різноманіття користувачів",
  "it is not just a tool": "це не просто інструмент, а помічник, який допомагає кожному крокувати з впевненістю у всьому світі",

  "roles": [
    {
      id: 1,
      photo: Andrii,
      name: "Андрій",
      surname: "Сукмановський",
      role: "генеральний директор",
    },
    {
      id: 2,
      photo: Ivan,
      name: "Іван",
      surname: "Левицький",
      role: "фінансовий директор",
    },
    {
      id: 3,
      photo: Sofia,
      name: "Софія",
      surname: "Мандзюк",
      role: "директор з маркетингу",
    },
    {
      id: 4,
      photo: Natalia,
      name: "Наталя",
      surname: "Федотова",
      role: "викладач та мотиватор",
    },
  ],

  "answering the most": "відповідаємо на",
  "asked questions": "популярні питання",

  //don't change the spaces in the "answer" value, it is necessary to separate paragraphs
  "faq": [
    {
      id: 1,
      isShow: false,
      question: "звідки взялась ідея проєкту?",
      answer: "Ідея виникла спонтанно під час нашої участі у програмі 'Компанія'. Наша команда шукала ідею, реалізація якої була б корисною та актуально. Ми спостерігали за труднощами, з якими стикаються наші близькі та знайомі з інвалідністю, і зрозуміли, що багато проблем можна вирішити за допомогою сучасних технологій",
    },
    {
      id: 2,
      isShow: false,
      question: "ви плануєте розвиватись далі?",
      answer: "Звісно! Ми плануємо проводити оновлення застосунку, щось змінювати з часом та додавати нове. У майбутньому хочемо заручитись підтримкою більшої кількості людей. Можливо, навіть створити громадську організацію чи фонд для допомоги людям з інвалідністю.",
    },
    {
      id: 3,
      isShow: false,
      question: "як я можу долучитись до проєкту?",
      answer: "Ви можете долучитися до проєкту в якості мецената або партнера — здійснити добровільний внесок для підтримки застосунку та допомогти у його розвитку таким чином. \n\n  Також ви можете зв'язатися з нами через соціальні мережі та внести свої пропозиції щодо вдосконалення, змін.",
    },
    {
      id: 4,
      isShow: false,
      question: "які переваги надає застосунок?",
      answer: "Наш застосунок має кілька переваг: \n \n 1. Легка навігація: Інтуїтивний і простий у використанні інтерфейс дозволяє легко користуватися додатком навіть людям з різними видами.\n\n   2. Широкий функціонал: Від нагадувань про ліки до навігації та SOS-виклику — наш застосунок об'єднує багато корисних інструментів в одному місці.\n\n   3. Інклюзивність: Створений з урахуванням різних потреб користувачів, забезпечуючи доступність та зручність для всіх користувачів, незалежно від їхніх.\n\n   4. Спільнота та підтримка: Дозволяє встановлювати контакти з іншими користувачами, обмінюватися досвідом та підтримувати один одного в спільноті.\n\n   5. Ефективна координація допомоги: Кнопка SOS та відстеження незрячих дозволяють швидко реагувати на надзвичайні ситуації та надавати допомогу у разі потреби.\n\n   Ці переваги дозволяють нашому застосунку бути корисним і потрібним інструментом для підтримки людей з обмеженими можливостями в повсякденному житті.",
    },
    {
      id: 5,
      isShow: false,
      question: "застосунком легко користуватись?",
      answer: "Так, ми працюємо над тим, щоб зробити застосунок максимально зручним і доступним для кожного. Використовуючи інтуїтивний інтерфейс, зрозумілі опції та функції, ми створюємо застосунок, який буде простим у використанні для людей з різними рівнями технічної підготовки та особливими потребами.",
    },
  ],

  "we make the world": "ми робимо світ для",
  "people": "людей",
  "litle": "трішки",
  "better": "кращим, і це точно від щирого серця",

  "application for people": "застосунок для людей",
  "answer all your questions": "відповімо на всі ваші питання",
  "chance app is a non-profit project": "chance app — учнівський проєкт, який створює застосунок для допомоги людям з обмеженими можливостями фізичного руху, незрячим людям, людям з вадами слуху та близьким родичам і опікунам",

  "infoFooter": [
    {
      title: "©2024 Chance app. Усі права захищені",
      link: null,
    },
    {
      title: "Політика конфіденційності",
      link: "/privacy-policy",
    },
    {
      title: "Умови користування",
      link: "/terms-of-use",
    },
    {
      title: "дизайн від @yasdubin",
      link: "https://yasdubin.notion.site/0a86c07baec1424f87bf25d1c2968151?pvs=4",
    },
    {
      title: "розробка від @andrii_yelieva",
      link: "https://www.linkedin.com/in/andriiyelieva/",
    },
  ],


  "cookies": "ми використовуемо файли cookies для більш гарної роботи сайту",
  "cookiesMore": "детальніше",
  "cookiesClose": "закрити",

  "page not found": "Сторінку не знайдено👀",
  "return to the main page": "повернутися на головну",

  "privacyPolicy": "політика конфіденційності",
  "theAdministration": "Адміністрація сайту зобов'язується дотримуватись принципів конфіденційності в Інтернеті. Ми дуже серйозно ставимося до захисту наданих вами особистих даних. Наша політика конфіденційності базується на вимогах",
  "generalDataProtection": " загального регламенту захисту персональних даних Європейського Союзу (GDPR). ",
  "We collect personal data": "Ми збираємо персональні дані з такими цілями:",

  "listText": [
    "Проведення маркетингових досліджень для покращення якості наших послуг та сервісу.",
    "Надання інформації про продукти, послуги та заходи, які можуть вас зацікавити.",
    "Покращення роботи нашого сервісу.",
    "Відправка новин та розсилок електронною поштою.",
    "Встановлення зв'язку з відвідувачами сайту.",
    "Надання запитаної вами інформації.",
  ],

  "listPermits": [
    {
      "title": "Збір та використання персональних даних",
      "description": "Ми збираємо та використовуємо ваші персональні дані тільки з вашої добровільної згоди. При наданні вашої згоди, ми можемо збирати та використовувати такі дані, як ваше ім'я, електронна пошта та номер телефону. Збір та обробка ваших даних здійснюються відповідно до законодавства Європейського Союзу та України.",
    },
    {
      "title": "Зберігання даних, зміна та видалення",
      "description": "Якщо ви бажаєте отримати доступ до своїх персональних даних, змінити їх або видалити, ви можете звернутися до адміністрації сайту за електронною поштою chanceapp4@gmail.com. Ми не передаємо ваші персональні дані третім особам без вашої згоди, за винятком випадків, передбачених законодавством.",
    },
    {
      "title": "Використання технічних даних під час відвідування сайту",
      "description": "Під час відвідування ми можемо зберігати технічну інформацію, таку як ваша IP-адреса, час відвідування, налаштування браузера, операційна система, а також інша технічна інформацію необхідну для коректного відображення вмісту сайту. Ці дані не дозволяють ідентифікувати особу відвідувача.",
    },
  ],
}